function loadScript(w, d, u) {
  var s = d.createElement('script');
  s.async = true;
  s.src = u + '?' + ((Date.now() / 60000) | 0);
  var h = d.getElementsByTagName('head')[0];
  h.appendChild(s);
}

// (function (w) {
//   w.dataLayer = w.dataLayer || [];
//   function gtag() {
//     dataLayer.push(arguments);
//   }
//   gtag('js', new Date());

//   gtag('config', 'UA-189828679-1');
// })(window);

// Only load in Client

if(typeof window !== 'undefined') {
  // Load Birtix Script
  loadScript(
    window,
    document,
    'https://cdn.bitrix24.com/b24603713/crm/site_button/loader_1_0jrgzv.js'
  );
  // Load GTag Script
  // TODO: Add Google Tag Manager ID Below before enabling the script.
  // loadScript(
  //   window,
  //   document,
  //   'https://www.googletagmanager.com/gtag/js?id=UA-189828679-1'
  // );
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-5RCSSQV');
}


